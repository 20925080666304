@charset "UTF-8";
/***  IMPORT   ***/
/* Eric Meyer's Reset CSS v2.0 - http://cssreset.com */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

strong {
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.kv-container--croisiere div, .kv-container--croisiere span, .kv-container--croisiere iframe, .kv-container--croisiere p, .kv-container--croisiere a, .kv-container--croisiere img, .kv-container--croisiere ul, .kv-container--croisiere li, .kv-container--croisiere header, .kv-container--croisiere nav, .kv-container--croisiere section {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }
.kv-container--croisiere header, .kv-container--croisiere nav, .kv-container--croisiere section {
  display: block; }
.kv-container--croisiere ul {
  list-style: none; }

/******************************************************************************************/
/******   SPRITES    *********/
/******************************************************************************************/
/******   COLORS    *********/
/*****************************************************************/
/******   LAYOUT    *********/
/******    HEADER    ******/
/******    NAVIGATION    ******/
/******    FOOOTER    ******/
/*****************************************************************/
/******   HOME AND COMMON    *********/
/******    MOTEUR    ******/
/****** SLIDER ************/
/****** DATEPICKER ************/
/****** NOUVELLE RECHERCHE ************/
/****** INFORMATION DESTI ************/
/****** TOP HOTEL ************/
/******    TOP DESTI    ******/
/****** AUTRE VOYAGE ************/
/****** TOUR OPERATOR ************/
/******    DERNIERE MINUTES    ******/
/******   BONS PLANS   ******/
/******   BLOCK TEL   ******/
/******   RECOMMANDATION   ******/
/******   CATEGORIE INFO   ******/
/******   TOP CATEGORIE   ******/
/*****************************************************************/
/******   FICHE PRODUIT     *********/
/******   BLOCK RECAP    ******/
/******* BLOCK MULTI ********/
/******* CALENDRIER ********/
/******* CONDITION ********/
/******* DESCRIPTION ********/
/******* AVIS CLIENT ********/
/******* PRODUIT SIMILAIRE ********/
/******* LIEN FP ********/
/*****************************************************************/
/******   SEJLIST     *********/
/******   AFFINAGE    ******/
/******   ENTETE SL    ******/
/******   ONGLET    ******/
/******   PAGINATION    ******/
/******   INFO SEJOUR    ******/
/******   GUIDE    ******/
/*****  BLOCK PRODUIT   *****/
/*****  ORDRE RESULTAT   *****/
/*****  PLUS IDEE SEJOUR   *****/
/***** CLASSE EXTEND ********/
/***** BREADCRUMB ********/
/***** TRIPADVISOR ********/
/***** PUB ********/
/***** ADSENSE ********/
/***** COOKIE ********/
/***** CROSSLIEN ********/
/*****************************************************************/
/******   PAGE MERCH     *********/
/*****************************************************************/
/******   SEJOUR     ****************************/
/*****************************************************************/
/******   INFORMATIONS    *********/
/*****************************************************************/
/******   SLIDER AGENCE    *********/
/*****************************************************************/
/******   MOYEN DE PAIEMENT    *********/
/*****************************************************************/
/******   POPUP AGENCE    *********/
/******   VACANCES A LA UNE AGENCE    *********/
/******************************************************************************************************/
/************      A SUPPRIMER une fois toute les pages specifiee comme ci-dessus     ********************/
/******************************************************************************************************/
/******************************************************************************************/
/******   Config Html    *********/
html, body {
  width: 100%; }

/******************************************************************************************/
/******   SPRITES    *********/
/******************************************************************************************/
/******   IMAGES    *********/
/******************************************************************************************/
/******   COLORS    *********/
/*****************************************************************/
/******   GENERAL    *********/
/*****************************************************************/
/******   LAYOUT    *********/
/******    HEADER    ******/
/******    BOTTOM HEADER    ******/
/******    NAVIGATION    ******/
/******    NAVIGATION > SUBMENU    ******/
/******    FOOOTER Refonte    ******/
/******    FOOOTER > INFORMATION COMPLEMENTAIRE    ******/
/*$color8*/
/*$color1;*/
/*$color10*/
/*****************************************************************/
/******   HOME AND COMMON    *********/
/******    MOTEUR    ******/
/****** SLIDER ************/
/****** DATEPICKER ************/
/****** NOUVELLE RECHERCHE ************/
/****** INFORMATION DESTI ************/
/****** AUTO PROMO ************/
/******   INSCRIPTION NEWSLETTER    *********/
/******   INSCRIPTION NEWSLETTER CRM   *********/
/******   INSCRIPTION NEWSLETTER POPUP  REFONTE *********/
/****** TOP HOTEL ************/
/******    TOP DESTI    ******/
/****** TOUR OPERATOR ************/
/****** AUTRE VOYAGE ************/
/******    DERNIERE MINUTES    ******/
/******   BONS PLANS   ******/
/******   ASSURANCE   ******/
/******   BLOCK TEL   ******/
/******   BLOCK RESERVATION AGENCE   ******/
/******   RECOMMANDATION   ******/
/******   INSPIRATION   ******/
/******   CATEGORIE INFO   ******/
/******   TOP CATEGORIE   ******/
/******   VOYAGE DE GROUPES    *********/
/******   BROCHURES FRAM    *********/
/*****************************************************************/
/******   HOME THEMATIQUE     *********/
/*****************************************************************/
/******   FICHE PRODUIT     *********/
/******   BLOCK RECAP    ******/
/******* BLOCK MULTI ********/
/******* CALENDRIER ********/
/******* CONDITION ********/
/******* DESCRIPTION ********/
/******* AVIS CLIENT ********/
/******* PRODUIT SIMILAIRE ********/
/******* LIEN FP ********/
/******* LIEN FP ********/
/*****************************************************************/
/******   SEJLIST     *********/
/***** OVERLAY ********/
/***** LOADER ********/
/******   MOTEUR    ******/
/******   AFFINAGE    ******/
/******   ENTETE SL    ******/
/******   ONGLET    ******/
/******   PAGINATION    ******/
/******   INFO SEJOUR    ******/
/******   GUIDE    ******/
/*****  BLOCK PRODUIT   *****/
/* [X] */
/* [X] */
/* [X] */
/* [X] */
/* [X] */
/*****  ORDRE RESULTAT   *****/
/*****  COUP DE COEUR   *****/
/*****  PLUS IDEE SEJOUR   *****/
/*****  AUCUN RESULTAT   *****/
/***** CLASSE EXTEND ********/
/***** BREADCRUMB ********/
/***** TRIPADVISOR ********/
/***** PUB ********/
/***** ADSENSE ********/
/***** COOKIE ********/
/***** CROSSLIEN ********/
/*****************************************************************/
/******   PAGE MERCH     *********/
/*****************************************************************/
/******   SEJOUR     ****************************/
/*****************************************************************/
/******   INFORMATIONS    *********/
/*****************************************************************/
/******   ERROR    *********/
/*****************************************************************/
/******   SLIDER AGENCE    *********/
/*****************************************************************/
/******   MOYEN DE PAIEMENT    *********/
/*****************************************************************/
/******   POPUP AGENCE    *********/
/*****************************************************************/
/******   DETAIL AGENCE    *********/
/******   Gmap AGENCE    *********/
/******   VACANCES A LA UNE AGENCE    *********/
/*************************************************************************************************************************/
/******   PROSPECTING ZONE  (Bloc contenant les 3 blocs voyage en groupe / newsletter / Tripdadvisor)  *********/
/***** TripAdvisor (HP) ********/
/*****************************************************************/
/******   PAGE OP    *********/
/*****************************************************************/
/******   PAGE OT    *********/
/******   NEWSLETTER DELAY   *********/
/*****************************************************************/
/****** MESSAGE PERSONNALISE *********/
/*****************************************************************/
/****** MESSAGE POP IN CALENDRIER FP *********/
/******************************************************************************************************/
/************      A SUPPRIMER une fois toute les pages specifiee comme ci-dessus     ********************/
/******************************************************************************************************/
/************* Mixin pour le box-shadow ***************************************
	$inset peut �tre � true ou false, mais false est la valeur par defaut
*******************************************************************************/
/*********** Mixin ************************************************************************************************************
	$font-size : Pour la taille de la police (Ex. 12px), 
  	$line-height : Pour la hauteur de ligne qui ne doit pas avoir d'unite (Ex. 1, ou encore 1.2,... et NON 10px, 12px ,...) 
    $lines-to-show : Pour le nombre de ligne qu'on souhaite afficher (Ex. 1, 2,...), 
    $max-width : Pour la largeur maximale sur lequel devra s'afficher notre element (Ex. 212px)
*******************************************************************************************************************************/
@font-face {
  font-family: 'icomoon';
  src: url('../../fonts/icomoon.eot?pqdxk1');
  src: url('../../fonts/icomoon.eot?pqdxk1#iefix') format('embedded-opentype'), url('../../fonts/icomoon.ttf?pqdxk1') format('truetype'), url('../../fonts/icomoon.woff?pqdxk1') format('woff'), url('../../fonts/icomoon.svg?pqdxk1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal; }

/******************************************************************************************/
/******   display espace client pour canal,espaceclient   *********/
.clientArea #espace-clients {
  display: none; }

/******************************************************************************************/
/******   HEADER    *********/
.kv-Nav-overlay {
  display: none;
  position: absolute;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  height: 500%;
  width: 100%;
  top: 152px;
  z-index: 9; }

header {
  background: #fff;
  width: 100%;
  position: relative;
  z-index: 1000; }
  header .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 109px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 9px 0 11px 0;
    box-sizing: border-box; }
  header .logo {
    width: 199px;
    min-width: 199px;
    height: 48px;
    text-indent: 199px;
    display: inline-block;
    vertical-align: top;
    margin: 0px;
    position: relative; }
    header .logo img {
      width: 100%;
      height: auto;
      position: absolute;
      top: 0;
      left: 0; }
    header .logo a {
      display: block;
      width: 199px;
      height: 48px;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0; }
  header div.header-ban {
    display: inline-block;
    vertical-align: top;
    width: 728px;
    text-align: center; }

/******************************************************************************************/
/******   HEADER INFO    *********/
.pull-right {
  float: right;
  width: 215px; }

.header-info {
  margin-: 0 0 10px 0;
  paddin: 5px 0;
  display: flex;
  flex-flow: wrap;
  min-width: 210px;
  max-width: 220px; }
  .header-info .bloc {
    display: block;
    padding-left: 5px;
    padding-right: 5px;
    background: #fff;
    color: #333;
    width: 100%; }
    .header-info .bloc + .bloc {
      padding-left: 25px;
      margin-left: 20px; }
    .header-info .bloc .text {
      display: block; }
    .header-info .bloc .infos {
      font-family: arial;
      font-weight: 400;
      font-size: 14px;
      text-transform: uppercase; }

#espace-clients {
  width: 100%;
  text-align: right;
  padding: 0;
  margin: 10px 0 0 0;
  background: none;
  box-shadow: none;
  border: 0; }
  #espace-clients .text p {
    display: inline-block;
    color: #5d5d5d;
    font-family: arial;
    font-size: 11px;
    width: 80px;
    white-space: nowrap; }
    #espace-clients .text p.infos {
      width: auto;
      text-transform: inherit; }
    #espace-clients .text p a, #espace-clients .text p [data-olf] {
      font-family: arial;
      font-size: 11px;
      color: #5d5d5d;
      display: inline-block;
      text-decoration: underline;
      cursor: pointer; }
      #espace-clients .text p a:hover, #espace-clients .text p [data-olf]:hover {
        font-family: arial;
        font-size: 11px;
        font-weight: 700; }

#bloc-tel-top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  background: none;
  box-shadow: none;
  border: 0;
  padding: 0; }
  #bloc-tel-top:before {
    content: "\e913";
    color: #e31c18;
    font-family: 'icomoon' !important;
    font-size: 35px;
    bottom: 4px;
    left: -4px;
    width: 32px;
    height: 34px;
    position: relative; }
  #bloc-tel-top > div {
    font-size: 0;
    width: auto; }
  #bloc-tel-top .infos {
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    color: #27343e;
    text-align: right; }
  #bloc-tel-top .numero, #bloc-tel-top a {
    font-size: 30px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    color: #e31c18;
    -ms-color: #fff !important;
    text-align: right; }
  #bloc-tel-top sup {
    vertical-align: top;
    font-family: arial;
    font-size: 11px;
    font-weight: bold; }
  #bloc-tel-top .detail {
    color: #8f8f8f;
    line-height: 10px;
    font-family: arial;
    font-size: 9px;
    margin: 0;
    text-align: right; }
    #bloc-tel-top .detail p {
      display: inline-block; }

/******************************************************************************************/
/******   NAVIGATION    *********/
/*
nav {
	width:100%;
	height:43px;
	margin:0 auto;
	clear: both;
	background:#d4d2d3;
	box-shadow:0px 2px 3px 0 rgba(0, 0, 0, 0.3);
	& *, & *:before, & *:after { box-sizing: border-box; }

	> ul {
		width:100%;
		min-width:1240px;
		max-width:1280px;
		height:43px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin:0 auto;
		position:relative;
		
	    li.onglet{
			display: inline-block;
			height:43px;
			
			width:auto;/
			display: flex;
			align-items: center;
			justify-content: center;
			&:hover{
					background:#e31c18;
					div.submenu { 
						display:flex;
						justify-content:space-between;
						align-items:flex-start;
						flex-flow:wrap;
					 } // afficher submenu
					 
					.linknav{
						color:#fff;
						border:none;
						.display-submenu{
							
							&:after{
								 border-top: 3px solid #fff;
						    	border-right: 3px solid transparent;
						    	border-left: 3px solid transparent;
							}
						}
					}
					+li.onglet{
						.linknav{
							border-left:none;
						}
					}
			}
			
			.linknav{
				width:auto;
				height:auto;
				box-sizing:border-box;
				display: inline-block;
				font-size:16px;
				font-family: 'Roboto Condensed', sans-serif; font-weight: 700;
				color:#393939;
				text-decoration: none;
				position:relative;
				text-align:center;
				border-left:1px solid #393939;
				padding: 0 38px;
				span{
					width:auto;
					display:inline-block;
					text-transform:uppercase;
					position:relative;
					white-space:nowrap;
					&.display-submenu{
						&:after{
							content:" ";
							position: absolute;
						    display: inline-block;
						    height: 0;
						    width: 0;
						     height: 0;
						    top: 32%;
						    right: -14px;
						    border-top: 3px solid #393939;
						    border-right: 3px solid transparent;
						    border-left: 3px solid transparent;
						}
					}
					
				}
			}
			&:first-child{
				.linknav{
					border-left:none;
				}
			}
			&:last-child{
				.linknav{
					border-right:1px solid #393939;
				}
				&:hover{
					.linknav{
					border-right:none;
				}
				}
			}
	>div.submenu {
		display: none;
		width:100%;
		min-width:1240px;
		max-width:1280px;
		max-height:192px;
		padding:20px 20px 20px 0;
		box-sizing:border-box;
		position: absolute;
		top: 43.4px;
		left: 0;
		background: $submenu-bg;
		font-size: 11px;
		z-index: 1002;
		text-align: left;
		
		
		.submenu-left{
			width:600px;
			display:flex;
			justify-content:flex-start;
			align-items:flex-start;
			flex-flow:wrap;
			height:152px;
			
			.title-submenu{
				width: 100%;
				text-transform:uppercase;
				color: #e31c18;
				padding:0 20px 0 20px;
				font-size:20px;
				font-family:RobotoCondensed-Bold;
			}
					
			>ul{
				min-width:253px;
				width:auto;
				height:112px;
				padding:0 0 0 20px;
				margin:0;
				>li{
					display:block;
					line-height:21pt;
					&:before{
						content:">";
						color: #e31c18;
						font-size:12px;
						font-family: 'Roboto Condensed', sans-serif; font-weight: 300;
						margin:0 7px 0 0;
					}
					+li{
						margin:0px 0 0 0;
					}
					a{
						font-size:16px;
						font-family: 'Roboto Condensed', sans-serif; font-weight: 300;
						border:none;
						padding:0;
						text-align:left;
						color:#525252;
						text-decoration:none;
						&:hover{
							text-decoration:underline;
						}
					}
					
				}
				+ul{
					border-left:1px solid  #e31c18;
				}
			}
			
		}

		 a.promote{
			width:632px;
			height:152px;
			background : #e31c18;
			display:inline-block;
			border:none;
			padding:0;
		}
	}
	
}
		
		// lien home
		> li.home{
			max-width: 45px;
			min-width: 45px;
			width:45px;
			height:43px;
			background:#393939; 
			position:relative;
				
				&:after {
					border-left: none; 
				} // enleve la separation du lien home
				a { 
					display:inline-block;
					padding: 0; 
					width:100%;
					height:43px;
					position:relative;
					z-index:2;
				}
				&:before{
						content:"\e912";
						font-family: 'icomoon' !important;
						font-size:20px;
						color:#fff;
						width: 100%;
						height:23px;
						position:absolute;
						top:11px;
						left:0;
						text-align:center;
						z-index:1;
				}
				+li.onglet{
					.linknav{
						border-left:none;
					}
				}
		}
	

	}
	
}*/
/******************************************************************************************/
/******   NAVIGATION NEW WIDGET   *********/
.kv-Nav-container {
  width: 100%;
  height: 43px;
  margin: 0 auto;
  clear: both;
  background: #d4d2d3;
  box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.3); }
  .kv-Nav-container > .kv-Nav-list {
    width: 100%;
    min-width: 1240px;
    max-width: 1280px;
    height: 43px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    position: relative; }
    .kv-Nav-container > .kv-Nav-list .kv-Nav-item {
      display: inline-block;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      background: #d4d2d3;
      cursor: pointer; }
      .kv-Nav-container > .kv-Nav-list .kv-Nav-item:last-Child a.lines.first {
        border-right: 1px solid #000; }
      .kv-Nav-container > .kv-Nav-list .kv-Nav-item:nth-child(2) a.lines.first {
        border-left: none; }
      .kv-Nav-container > .kv-Nav-list .kv-Nav-item:hover {
        background: #e31c18; }
        .kv-Nav-container > .kv-Nav-list .kv-Nav-item:hover:before, .kv-Nav-container > .kv-Nav-list .kv-Nav-item:hover:after {
          background: none; }
        .kv-Nav-container > .kv-Nav-list .kv-Nav-item:hover + .kv-Nav-item:before {
          background: none; }
        .kv-Nav-container > .kv-Nav-list .kv-Nav-item:hover .kv-Nav-item--submenu {
          display: flex;
          align-items: flex-start;
          flex-flow: nowrap; }
        .kv-Nav-container > .kv-Nav-list .kv-Nav-item:hover a.lines.first {
          color: #fff;
          border-color: transparent; }
        .kv-Nav-container > .kv-Nav-list .kv-Nav-item:hover + li a.lines.first {
          border-color: transparent; }
        .kv-Nav-container > .kv-Nav-list .kv-Nav-item:hover + li:last-Child a.lines.first {
          border-right: 1px solid #000; }
        .kv-Nav-container > .kv-Nav-list .kv-Nav-item:hover + li:last-Child:hover {
          border-color: transparent; }
      .kv-Nav-container > .kv-Nav-list .kv-Nav-item a.lines.first {
        width: 100%;
        height: auto;
        box-sizing: border-box;
        font-size: 16px;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 700;
        color: #393939;
        /* $nav-text*/
        text-decoration: none;
        padding: 0 33px;
        border-left: 1px solid #000;
        white-space: nowrap;
        text-transform: uppercase;
        display: inline-block;
        position: relative;
        text-align: center; }
      .kv-Nav-container > .kv-Nav-list .kv-Nav-item.kv-Nav-item--wSub a.lines.first {
        position: relative; }
        .kv-Nav-container > .kv-Nav-list .kv-Nav-item.kv-Nav-item--wSub a.lines.first:after {
          content: " ";
          position: absolute;
          display: inline-block;
          top: 5px;
          width: 0;
          height: 0;
          margin: 0 0 0 7px;
          border-top: 3px solid #393939;
          border-right: 3px solid transparent;
          border-left: 3px solid transparent; }
      .kv-Nav-container > .kv-Nav-list .kv-Nav-item.kv-Nav-item--wSub:hover a.lines.first:after {
        border-top: 3px solid #fff;
        border-right: 3px solid transparent;
        border-left: 3px solid transparent; }
      .kv-Nav-container > .kv-Nav-list .kv-Nav-item.kv-Nav-item--home {
        max-width: 45px;
        min-width: 45px;
        width: 45px;
        height: 43px;
        background: #393939;
        /*$nav-home-bg;*/
        position: relative; }
        .kv-Nav-container > .kv-Nav-list .kv-Nav-item.kv-Nav-item--home a.lines.first, .kv-Nav-container > .kv-Nav-list .kv-Nav-item.kv-Nav-item--home [data-olf] {
          display: inline-block;
          padding: 0;
          width: 100%;
          height: 43px;
          position: relative;
          z-index: 2;
          border-left: transparent; }
        .kv-Nav-container > .kv-Nav-list .kv-Nav-item.kv-Nav-item--home:before {
          content: "\e912";
          font-family: 'icomoon' !important;
          font-size: 20px;
          color: #fff;
          width: 100%;
          height: 23px;
          position: absolute;
          top: 11px;
          left: 0;
          text-align: center;
          z-index: 1;
          background: none; }
        .kv-Nav-container > .kv-Nav-list .kv-Nav-item.kv-Nav-item--home + .kv-Nav-item:before {
          background: none; }
  .kv-Nav-container ul.kv-Nav-item--submenu {
    display: none;
    width: 100%;
    min-width: 1240px;
    max-width: 1280px;
    height: 192px;
    padding: 20px 20px 20px 0;
    box-sizing: border-box;
    position: absolute;
    top: 43.4px;
    left: 0;
    background: #fff;
    font-size: 11px;
    z-index: 10;
    text-align: left;
    flex-flow: nowrap;
    box-shadow: 0.9px 13px 20px 0 rgba(0, 0, 0, 0.3); }
    .kv-Nav-container ul.kv-Nav-item--submenu li {
      width: 23%;
      /*600px*/
      min-height: 155px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-flow: wrap; }
      .kv-Nav-container ul.kv-Nav-item--submenu li p {
        width: 100%;
        text-transform: uppercase;
        color: #e31c18;
        padding: 0 20px 0 20px;
        font-size: 20px;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 700;
        height: 18px; }
      .kv-Nav-container ul.kv-Nav-item--submenu li ul {
        width: 100%;
        min-height: 135px;
        padding: 20px 0 0 20px;
        display: flex;
        flex-flow: wrap;
        flex-direction: column;
        justify-content: flex-start;
        box-sizing: border-box; }
        .kv-Nav-container ul.kv-Nav-item--submenu li ul li {
          max-width: 260px;
          min-width: 215px;
          width: 100%;
          min-height: auto;
          align-content: center;
          padding: 0 0 10px 0;
          line-height: 16pt; }
          .kv-Nav-container ul.kv-Nav-item--submenu li ul li span:before {
            content: ">";
            color: #e31c18;
            font-size: 16px;
            line-height: initial;
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: 400;
            margin: 0 7px 0 0; }
          .kv-Nav-container ul.kv-Nav-item--submenu li ul li a {
            font-size: 18px;
            font-family: 'Roboto Condensed', sans-serif;
            font-weight: 400;
            border: none;
            padding: 0;
            text-align: left;
            color: #525252;
            text-decoration: none; }
            .kv-Nav-container ul.kv-Nav-item--submenu li ul li a:hover {
              text-decoration: underline;
              font-weight: 700; }
          .kv-Nav-container ul.kv-Nav-item--submenu li ul li:nth-child(5) {
            position: relative; }
            .kv-Nav-container ul.kv-Nav-item--submenu li ul li:nth-child(5):before {
              content: "";
              display: inline-block;
              position: absolute;
              height: 114px;
              width: 1px;
              left: 0; }
          .kv-Nav-container ul.kv-Nav-item--submenu li ul li:nth-child(n+5) {
            padding: 0 0 0 50px; }
          .kv-Nav-container ul.kv-Nav-item--submenu li ul li:last-child {
            padding: 0; }
      .kv-Nav-container ul.kv-Nav-item--submenu li + li ul {
        padding: 20px 0 0 70px;
        position: relative; }
        .kv-Nav-container ul.kv-Nav-item--submenu li + li ul:before {
          content: "";
          border-left: 1px solid #e31c18;
          height: 104px;
          position: absolute;
          bottom: 3px;
          left: 0; }
    .kv-Nav-container ul.kv-Nav-item--submenu li.kv-NavSub-vignette {
      width: 632px;
      height: 152px;
      overflow: hidden;
      border: none;
      padding: 0; }
      .kv-Nav-container ul.kv-Nav-item--submenu li.kv-NavSub-vignette a, .kv-Nav-container ul.kv-Nav-item--submenu li.kv-NavSub-vignette div {
        width: 100%;
        display: inline-block; }
        .kv-Nav-container ul.kv-Nav-item--submenu li.kv-NavSub-vignette a img, .kv-Nav-container ul.kv-Nav-item--submenu li.kv-NavSub-vignette div img {
          /*width:100%;
					height:auto;*/
          width: 632px;
          height: 152px;
          object-fit: cover; }

.kv-Nav-item [data-olf] {
  width: 100%;
  height: auto;
  font-size: 16px;
  font-family: 'Roboto Condensed',sans-serif;
  font-weight: 700;
  color: #393939;
  text-decoration: none;
  padding: 0 33px;
  border-left: 1px solid #000;
  white-space: nowrap;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  text-align: center; }
  .kv-Nav-item [data-olf]:hover {
    color: #fff; }
.kv-Nav-item:hover span {
  color: #fff; }

/******************************************************************************************/
/******   BOTTOM HEADER    *********/
.botton-header-left, .botton-header-right, #bloc-tel-inline {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  line-height: 30px; }

.botton-header-left {
  width: 65%; }

.botton-header-right, #bloc-tel-inline {
  width: 35%;
  text-align: right;
  vertical-align: top; }

#bottom-header {
  line-height: 30px;
  margin-left: 17px;
  margin-right: 17px;
  background: #fff;
  color: #333;
  font-size: 0; }
  #bottom-header .kv-Header-empty {
    display: inline-block;
    width: 47%; }
  #bottom-header h1 {
    width: 50%;
    display: inline-block;
    font-family: arial;
    font-size: 11px;
    color: #333; }
  #bottom-header span.search {
    display: inline-block;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: 6px -600px;
    background-color: transparent;
    background-image: url('../../img/common/sprite-global.png');
    height: 25px;
    width: 21px;
    margin-right: 5px; }

#search-agence {
  font-family: arial;
  font-size: 11px;
  font-weight: 700;
  color: #333;
  text-decoration: none; }
  #search-agence:hover {
    text-decoration: underline; }

#bloc-tel-inline {
  width: 28%; }
  #bloc-tel-inline p {
    font-family: arial;
    font-size: 14px; }
  #bloc-tel-inline span.icon {
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: 0 -372px;
    background-color: transparent;
    background-image: url('../../img/common/sprite-global.png');
    height: 18px;
    width: 18px;
    margin-right: 3px; }
  #bloc-tel-inline .numero, #bloc-tel-inline a {
    font-family: arial;
    font-size: 16px;
    font-weight: bold;
    color: #e32613; }
  #bloc-tel-inline sup {
    font-family: arial;
    font-size: 16px;
    font-weight: bold;
    vertical-align: top; }

/*Tab*/
@media (min-width: 768px) and (max-width: 1024px) {
  header .kv-Nav-container > .kv-Nav-list {
    width: 72%;
    min-width: 72%;
    margin: 0; }
    header .kv-Nav-container > .kv-Nav-list .kv-Nav-item.kv-Nav-item--home {
      max-width: 36px;
      min-width: 36px; }
    header .kv-Nav-container > .kv-Nav-list .kv-Nav-item a.lines.first {
      padding: 0 19px; } }

#breadcrumb {
  vertical-align: bottom;
  width: 72%;
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
  height: 30px;
  margin-bottom: 6px;
  font-family: arial, arial;
  font-size: 11px;
  font-weight: normal; }
  #breadcrumb div {
    display: inline-block;
    vertical-align: middle; }
    #breadcrumb div strong, #breadcrumb div b {
      font-weight: bold; }
    #breadcrumb div a {
      color: #333;
      text-decoration: none; }
      #breadcrumb div a strong, #breadcrumb div a b {
        font-weight: normal; }

#kv-container, #container {
  line-height: 1;
  width: 998px;
  margin: 0 auto;
  background: #fff; }
  #kv-container #bloc-tel-inline, #container #bloc-tel-inline {
    float: right; }
  #kv-container nav .submenu, #container nav .submenu {
    width: 120%; }
  #kv-container nav ul li a, #container nav ul li a {
    width: 102% !important; }

#information-complementaire {
  position: relative;
  background: #f4F6F6;
  clear: both;
  float: none;
  width: 100%; }
  #information-complementaire *, #information-complementaire *:before, #information-complementaire *:after {
    box-sizing: border-box; }
  #information-complementaire .content {
    min-width: 1240px;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 16px 0 18px;
    text-align: center; }
  #information-complementaire .column-container {
    width: 100%;
    display: flex;
    margin: 0;
    justify-content: center;
    align-content: center; }
  #information-complementaire .column {
    /*width: 20%;*/
    width: 212px;
    display: block; }
    #information-complementaire .column + .column {
      margin: 0 0 0 54px; }
    #information-complementaire .column:nth-child(4) {
      width: 214px;
      margin: 0 0 0 34px; }
    #information-complementaire .column p.subtitle {
      font-size: 15px;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 700;
      width: 100%;
      margin: 0 auto 6px; }
    #information-complementaire .column p {
      font-family: arial;
      font-size: 11px;
      color: #27343e;
      margin: 0; }
  #information-complementaire .title-container {
    width: 100%;
    margin: 0 0 10px 0;
    text-align: center; }
    #information-complementaire .title-container h2 {
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: #27343e;
      text-transform: uppercase; }

.smile, .padlock, .headphones, .world, .marker {
  display: block;
  width: 100%;
  margin: 0 0 11px 0; }
  .smile:before, .padlock:before, .headphones:before, .world:before, .marker:before {
    color: #e2290d;
    /*font-size:;*/
    width: 47px;
    height: 47px;
    display: inline-block;
    font-family: 'icomoon' !important;
    font-size: 45px; }

.smile:before {
  content: "\e90c"; }

.padlock:before {
  content: "\e90b"; }

.headphones:before {
  content: "\e90d"; }

.world:before {
  content: "\e904"; }

.marker:before {
  content: "\e90e"; }

footer {
  font-size: 16px;
  background: #393939;
  width: 100%;
  min-width: 1240px;
  padding: 2.875rem 0 1.526rem 0;
  font-family: 'RobotoCondensed-Bold', sans-serif; }
  footer *, footer *:before, footer *:after {
    box-sizing: border-box; }
  footer .content {
    padding: 0;
    width: 100%;
    min-width: 1240px;
    max-width: 1280px;
    margin: 0 auto; }
  footer .column-container {
    display: flex;
    width: 100%;
    max-width: 1280px;
    min-width: 1240px;
    margin: 0 auto;
    justify-content: space-between; }
  footer .column {
    width: 230px; }
    footer .column .logo {
      width: 144px;
      height: auto; }
    footer .column > p, footer .column .encart-tel > p, footer .column #frm_refsearch > p {
      font-size: 16px;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 700;
      color: #d4d2d3;
      text-transform: uppercase; }
    footer .column + .column {
      width: 660px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap; }
      footer .column + .column + .column {
        width: 270px;
        justify-content: flex-start;
        align-content: start; }
        footer .column + .column + .column p {
          margin: 0 0 0.625rem 0; }
    footer .column .inscription-newsletter {
      width: 100%; }
    footer .column .serviceClient, footer .column .infoPratique {
      width: 36%; }
      footer .column .serviceClient p, footer .column .infoPratique p {
        font-size: 16px;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 700;
        color: #d4d2d3;
        margin: 0 0 9px 0;
        text-transform: uppercase; }
    footer .column .infosReservation {
      width: 28%; }
      footer .column .infosReservation p {
        width: 100%; }
      footer .column .infosReservation #bloc-tel-footer > .numero {
        margin: 0 0 6px 0;
        font-size: 16px;
        color: #fff;
        -ms-color: #fff !important; }
      footer .column .infosReservation #bloc-tel-footer > .detail, footer .column .infosReservation #bloc-tel-footer > .sup {
        font-size: 11px;
        margin: 0;
        text-transform: none; }
  footer ul {
    display: inline-block;
    font-family: arial;
    font-size: 12px;
    color: #fff; }
    footer ul a, footer ul span {
      text-decoration: none;
      font-family: arial;
      font-size: 12px;
      color: #fff;
      line-height: 25px;
      cursor: pointer; }
      footer ul a:hover, footer ul span:hover {
        color: #e2290d;
        text-decoration: underline; }
  footer ul.with-bullet li:before {
    content: ">";
    font-size: 12px;
    color: #e2290d;
    margin-right: 5px; }
  footer ul.social {
    margin: 0 0 1.875rem 0; }
    footer ul.social li {
      display: inline-block;
      vertical-align: top;
      position: relative; }
      footer ul.social li + li {
        margin-left: 0.25rem; }
    footer ul.social a, footer ul.social [data-olf] {
      display: inline-block;
      vertical-align: top;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      -webkit-border-radius: 67px;
      -moz-border-radius: 67px;
      -ms-border-radius: 67px;
      border-radius: 67px;
      width: 35px;
      height: 35px;
      background-color: #fff; }
      footer ul.social a:hover, footer ul.social [data-olf]:hover {
        background-color: #e2290d;
        transition: all .2s ease; }
        footer ul.social a:hover span:before, footer ul.social [data-olf]:hover span:before {
          color: #fff; }
  footer ul.typePaiement {
    margin: 0 0 1.5rem 0; }
    footer ul.typePaiement li {
      display: inline-flex;
      justify-content: flex-start;
      align-content: center;
      width: 46px;
      height: 31px;
      background: #fff;
      border-radius: 5px; }
      footer ul.typePaiement li + li {
        margin: 0 0 0 4px; }
      footer ul.typePaiement li span {
        display: block;
        width: 100%;
        background: url(../../img/common/sprite-payment.png) no-repeat;
        cursor: default; }
        footer ul.typePaiement li span:before {
          display: block;
          width: 100%;
          height: auto; }
      footer ul.typePaiement li .cb {
        background-position: 3px 2px; }
      footer ul.typePaiement li .mastercard {
        background-position: -37px 1px; }
      footer ul.typePaiement li .americanexpress {
        background-position: -163px 1px; }
      footer ul.typePaiement li .ancv {
        background-position: -119px 1px; }
      footer ul.typePaiement li .visa {
        background-position: -79px 1px; }
  footer #frm_refsearch {
    width: 100%;
    flex-flow: wrap; }
    footer #frm_refsearch p {
      margin: 0 0 0.625rem 0; }
    footer #frm_refsearch input#refSai {
      /*width: 205px;*/
      width: 74%;
      max-width: 205px;
      height: 2.375rem;
      border: 1px solid #888888;
      background-color: #fff;
      font-family: arial;
      font-size: 11px;
      font-style: normal4;
      color: #808080;
      padding: 0 0 0 9px; }
    footer #frm_refsearch .submit {
      width: 3.0625rem;
      height: 2.375rem;
      border: 2px solid #d4d2d3;
      border-radius: 3px;
      background-color: #393939;
      font-size: 0.875rem;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 700;
      color: #d4d2d3;
      text-transform: uppercase;
      margin: 0 0 0 8px;
      padding: 0;
      font-style: normal;
      cursor: pointer; }
      footer #frm_refsearch .submit:hover {
        text-decoration: none;
        color: #393939;
        background: #fff;
        border: 0px solid #393939;
        transition: all .2s ease;
        border-radius: 3px; }
  footer p.brand {
    font-size: 16px;
    font-family: 'Roboto Condensed', sans-serif !important;
    font-weight: 700;
    color: #fff;
    border-bottom: none;
    margin: 0.875rem 0 0 0;
    cursor: default;
    line-height: 24px; }
  footer .mention {
    margin: 1.25rem auto 0;
    padding-top: 2rem;
    border-top: 1px dotted #787474;
    max-width: 1280px;
    text-align: center; }
    footer .mention p, footer .mention a {
      font-family: arial;
      font-size: 0.625rem;
      color: #fff;
      -ms-color: #fff !important; }
    footer .mention .ttc {
      margin-bottom: 0.625rem; }
    footer .mention .canal {
      margin-top: 0.9375rem; }

.facebook, .blog, .youtube, .twitter, .instagram, .pinterest {
  color: #333;
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  font-size: 18px;
  line-height: 1;
  -webkit-font-smoothing: antialiased; }

.facebook:before {
  content: "\e922"; }

.twitter:before {
  content: "\e923"; }

.youtube:before {
  content: "\e927"; }

.blog:before {
  content: "\e902"; }

.pinterest {
  left: 8px; }
  .pinterest:before {
    content: "\e926"; }

.instagram:before {
  content: "\e903"; }

/*.encart-tel { margin-top: 25px; }*/
#bloc-tel-footer {
  color: #fff; }
  #bloc-tel-footer .numero, #bloc-tel-footer a {
    font-family: arial;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.625rem;
    color: #fff; }
  #bloc-tel-footer .numeron {
    margin: 0 0 5px 0; }
  #bloc-tel-footer .detail {
    font-family: arial;
    font-size: 9px;
    line-height: 9px;
    color: #fff; }
  #bloc-tel-footer sup {
    display: block;
    font-family: arial;
    font-size: 9px;
    line-height: 1.0625rem; }

footer #inscription-newsletter-footer {
  width: 100%;
  background: none;
  margin: 0 0 1.25rem 0; }
  footer #inscription-newsletter-footer .kv-NewsletterCRM {
    text-align: left; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM p.title {
      width: 100%;
      font-size: 16px;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 700;
      text-align: left;
      color: #d4d2d3;
      padding: 0;
      margin: 0 0 7px 0; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM p.title:before {
        content: none; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM .kv-NewsletterCRM-mandatoriesFields {
      text-align: right;
      color: #fff;
      font-family: arial;
      font-size: 9px;
      width: 260px;
      display: inline-block; }
  footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription {
    display: flex;
    justify-content: flex-start;
    flex-flow: nowrap;
    width: 100%; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-email, footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity {
      width: 276px !important;
      min-width: 261px; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-email.error .kv-TextInput:after {
      color: #e2290d; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput, footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity {
      background: #fff;
      height: 2.375rem;
      border: 1px solid #888888;
      display: flex;
      align-items: center; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput {
      position: relative;
      width: 100%; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput:after {
        content: "\e90f";
        display: block;
        width: 19px;
        height: 16px;
        position: absolute;
        top: 0.625rem;
        right: 13px;
        font-family: icomoon !important;
        font-size: 1.0625rem;
        color: #808080; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput input {
        width: 100%;
        height: 2.375rem;
        border: none;
        font-size: 0.875rem;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        color: #808080;
        padding: 0 0.625rem; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput input.kv-NewsletterCRM--error {
          border: 1px solid #e2290d; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput input::placeholder {
          font-style: italic; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput .kv-NewsletterCRM-errorMessage {
        width: 276px;
        color: #fff;
        background: #e2290d;
        font-size: 13px;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        font-style: normal;
        bottom: -52px;
        left: 0;
        padding: 12px 0 0 0;
        text-align: center; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput .kv-NewsletterCRM-errorMessage:before {
          display: none; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-TextInput .kv-NewsletterCRM-errorMessage:after {
          border-top: 9px solid transparent;
          border-bottom: 9px solid #e52f00;
          border-left: 9px solid transparent;
          border-right: 9px solid transparent;
          top: -21px; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity {
      margin: 0 0 0 9px; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput {
        border: none;
        font-size: 0.875rem;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        color: #808080;
        width: 100%;
        position: relative; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-label {
        width: 100%;
        height: 2.375rem;
        border: none;
        font-size: 0.875rem;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 300;
        color: #808080;
        padding: 0 0.625rem;
        display: inline-flex;
        align-items: center; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-label::after {
          position: absolute;
          right: 0.625rem;
          top: 16px;
          height: 0;
          width: 0;
          border-top: 5px solid #808080;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options {
        width: 100%;
        position: absolute;
        left: 0px;
        top: 38px;
        border: none; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options .kv-SelectInput-title {
          font-family: arial;
          font-size: 0.75rem;
          font-weight: bold;
          color: #e2290d;
          display: block; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options ul {
          width: 100%; }
          footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options ul li {
            font-family: arial;
            font-size: 0.75rem;
            color: #808080;
            padding: 0 0 0 0.75rem; }
            footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options ul li:hover {
              /*background: $footer-input-hover;*/ }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options .kv-MoreCities .kv-MoreCities--more {
          color: #e2290d; }
          footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options .kv-MoreCities .kv-MoreCities--more:before {
            color: #e2290d; }
        footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options .kv-MoreCities .kv-MoreCities--more, footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-departureCity .kv-SelectInput-options .kv-MoreCities .kv-MoreCities--less {
          font-family: arial;
          font-size: 0.75rem;
          color: #e2290d;
          text-decoration: underline; }
    footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-sendData {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 126px !important;
      height: 2.375rem;
      border: 2px solid #d4d2d3;
      background: #393939;
      font-size: 0.875rem;
      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 700;
      color: #d4d2d3;
      margin: 0 0 0 9px;
      border-radius: 3px; }
      footer #inscription-newsletter-footer .kv-NewsletterCRM-inscription .kv-NewsletterCRM-sendData:hover {
        text-decoration: none;
        color: #393939;
        background: #fff;
        border: 0px solid #393939;
        transition: all .2s ease;
        border-radius: 3px; }

/******************************************************************************************/
/******   BOTTOM HEADER    *********/
#kv-container .kv-botton-header-left, #kv-container .kv-botton-header-right, #kv-container #kv-bloc-tel-inline, #kv-container #container #bloc-tel-inline, #container #kv-container #bloc-tel-inline, #container .botton-header-left, #container .botton-header-right, #container #bloc-tel-inline {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  line-height: 30px; }

#kv-container .kv-botton-header-left, #container .botton-header-left {
  width: 65%; }

#kv-container .kv-botton-header-right, #kv-container #kv-bloc-tel-inline, #kv-container #container #bloc-tel-inline, #container #kv-container #bloc-tel-inline, #container .botton-header-right, #container #bloc-tel-inline {
  width: 35%;
  text-align: right; }

#kv-container #kv-bottom-header, #container #bottom-header {
  height: 30px;
  line-height: 30px;
  margin-left: 17px;
  margin-right: 17px;
  background: #fff;
  color: #333;
  font-size: 0; }
  #kv-container #kv-bottom-header h1, #container #bottom-header h1 {
    width: 74%;
    display: inline-block;
    font-family: arial, arial;
    font-size: 11px;
    font-weight: normal;
    color: #333; }
  #kv-container #kv-bottom-header span.kv-search, #container #bottom-header span.kv-search {
    display: inline-block;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: 6px 100%;
    background-color: transparent;
    background-image: url('../../img/common/sprite-global.png');
    height: 25px;
    width: 21px;
    margin-right: 5px;
    background-size: 143%; }

#kv-container #kv-search-agence, #container #search-agence {
  font-family: arial, arial;
  font-size: 11px;
  font-weight: normal;
  font-weight: 700;
  color: #333;
  text-decoration: none; }
  #kv-container #kv-search-agence:hover, #container #search-agence:hover {
    text-decoration: underline; }

#kv-container #kv-bloc-tel-inline, #container #bloc-tel-inline {
  width: 28%; }
  #kv-container #kv-bloc-tel-inline p, #container #bloc-tel-inline p {
    font-family: arial, arial;
    font-size: 14px;
    font-weight: normal; }
  #kv-container #kv-bloc-tel-inline span.icon, #container #bloc-tel-inline span.icon {
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: 0 -372px;
    background-color: transparent;
    background-image: url('../../img/common/sprite-global.png');
    height: 18px;
    width: 18px;
    margin-right: 3px; }
  #kv-container #kv-bloc-tel-inline .kv-numero, #kv-container #kv-bloc-tel-inline .numero, #kv-container #kv-bloc-tel-inline a, #container #bloc-tel-inline .kv-numero, #container #bloc-tel-inline .numero, #container #bloc-tel-inline a {
    font-family: arial, arial;
    font-size: 16px;
    font-weight: bold;
    color: #e32613; }
  #kv-container #kv-bloc-tel-inline sup, #container #bloc-tel-inline sup {
    font-family: arial, arial;
    font-size: 16px;
    font-weight: bold;
    vertical-align: top; }

/******************************************************************************************/
/******   BREADCRUMB    *********/
#kv-container section#kv-bottom-header > div, #kv-container section#kv-breadcrumb, #container section#bottom-header > div, #container section#breadcrumb {
  width: 60%;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
  font-family: arial, arial;
  font-size: 11px;
  font-weight: normal;
  padding-top: 0; }
  #kv-container section#kv-bottom-header > div div, #kv-container section#kv-breadcrumb div, #container section#bottom-header > div div, #container section#breadcrumb div {
    display: inline-block;
    vertical-align: middle; }
    #kv-container section#kv-bottom-header > div div strong, #kv-container section#kv-bottom-header > div div b, #kv-container section#kv-breadcrumb div strong, #kv-container section#kv-breadcrumb div b, #container section#bottom-header > div div strong, #container section#bottom-header > div div b, #container section#breadcrumb div strong, #container section#breadcrumb div b {
      font-weight: bold; }
    #kv-container section#kv-bottom-header > div div a, #kv-container section#kv-breadcrumb div a, #container section#bottom-header > div div a, #container section#breadcrumb div a {
      color: #333;
      text-decoration: none; }
      #kv-container section#kv-bottom-header > div div a strong, #kv-container section#kv-bottom-header > div div a b, #kv-container section#kv-breadcrumb div a strong, #kv-container section#kv-breadcrumb div a b, #container section#bottom-header > div div a strong, #container section#bottom-header > div div a b, #container section#breadcrumb div a strong, #container section#breadcrumb div a b {
        font-weight: normal; }

/******************************************************************************************/
/******   BOTTOM HEADER    *********/
#container .kv-botton-header-left, #container .kv-botton-header-right, #container #bloc-tel-inline, #container #kv-bloc-tel-inline {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  line-height: 30px; }

#container .kv-botton-header-left {
  width: 65%; }

#container .kv-botton-header-right, #container #bloc-tel-inline, #container #kv-bloc-tel-inline {
  width: 35%;
  text-align: right; }

#container #kv-bottom-header {
  height: 30px;
  line-height: 30px;
  margin-left: 17px;
  margin-right: 17px;
  background: #fff;
  color: #333;
  font-size: 0; }
  #container #kv-bottom-header h1 {
    width: 74%;
    display: inline-block;
    font-family: arial, arial;
    font-size: 11px;
    font-weight: normal;
    color: #333; }
  #container #kv-bottom-header span.kv-search {
    display: inline-block;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: 6px 100%;
    background-color: transparent;
    background-image: url('../../img/common/sprite-global.png');
    height: 25px;
    width: 21px;
    margin-right: 5px;
    background-size: 143%; }

#container #kv-search-agence {
  font-family: arial, arial;
  font-size: 11px;
  font-weight: normal;
  font-weight: 700;
  color: #333;
  text-decoration: none; }
  #container #kv-search-agence:hover {
    text-decoration: underline; }

#container #kv-bloc-tel-inline {
  width: 28%; }
  #container #kv-bloc-tel-inline p {
    font-family: arial, arial;
    font-size: 14px;
    font-weight: normal; }
  #container #kv-bloc-tel-inline span.icon {
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: 0 -372px;
    background-color: transparent;
    background-image: url('../../img/common/sprite-global.png');
    height: 18px;
    width: 18px;
    margin-right: 3px; }
  #container #kv-bloc-tel-inline .kv-numero, #container #kv-bloc-tel-inline a {
    font-family: arial, arial;
    font-size: 16px;
    font-weight: bold;
    color: #e32613; }
  #container #kv-bloc-tel-inline sup {
    font-family: arial, arial;
    font-size: 16px;
    font-weight: bold;
    vertical-align: top; }

/******************************************************************************************/
/******   BREADCRUMB    *********/
#container section#kv-bottom-header > div, #container section#kv-breadcrumb {
  width: 72%;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  line-height: 30px;
  font-family: arial, arial;
  font-size: 11px;
  font-weight: normal; }
  #container section#kv-bottom-header > div div, #container section#kv-breadcrumb div {
    display: inline-block;
    vertical-align: middle; }
    #container section#kv-bottom-header > div div strong, #container section#kv-bottom-header > div div b, #container section#kv-breadcrumb div strong, #container section#kv-breadcrumb div b {
      font-weight: bold; }
    #container section#kv-bottom-header > div div a, #container section#kv-breadcrumb div a {
      color: #333;
      text-decoration: none; }
      #container section#kv-bottom-header > div div a strong, #container section#kv-bottom-header > div div a b, #container section#kv-breadcrumb div a strong, #container section#kv-breadcrumb div a b {
        font-weight: normal; }

/******************************************************************************************/
/****** OUT CONTAINER BREADCRUMB NEW HEADER   *********/
#kv-bottom-header {
  width: 100%; }
  #kv-bottom-header #breadcrumb {
    width: 100%;
    max-width: 1280px;
    min-width: 1240px;
    margin: 0 auto;
    display: block; }
